<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(saveChanges)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('PageAccount.general.userName')"
              label-for="account-username"
            >
              <b-form-input
                v-model="profileData.userName"
                :placeholder="$t('PageAccount.general.userName')"
                name="username"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              :name="$t('PageAccount.general.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('PageAccount.general.name')"
                label-for="account-name"
              >
                <b-form-input
                  id="account-name"
                  v-model="profileData.name"
                  name="name"
                  :placeholder="$t('PageAccount.general.name')"
                  :state="getValidationState(validationContext)"
                  autofocus
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              :name="$t('PageAccount.general.email')"
              rules="required|email"
            >
              <b-form-group
                :label="$t('PageAccount.general.email')"
                label-for="account-email"
              >
                <b-form-input
                  id="account-email"
                  v-model="profileData.email"
                  name="email"
                  :placeholder="$t('PageAccount.general.email')"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              :name="$t('PageAccount.general.phoneNumber')"
              rules="required|regex:^([0-9\-\s\(\)]+)$"
            >
              <b-form-group
                :label="$t('PageAccount.general.phoneNumber')"
                label-for="account-phonenumber"
              >
                <b-form-input
                  id="account-phonenumber"
                  v-model="profileData.phoneNumber"
                  name="phonenumber"
                  :placeholder="$t('PageAccount.general.phoneNumber')"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              {{ $t("PageAccount.general.btnSaveChanges") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t("PageAccount.general.btnReset") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import accountService from '@/services/account.service'
import useCommon from '@/libs/common'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileData: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    resetForm() {
      this.profileData = JSON.parse(JSON.stringify(this.generalData))
    },
    saveChanges() {
      this.updateProfile(this.profileData, () => {
        this.common.toastSuccess('Perfil modificado')
        router.go()
      })
    },
  },
  setup() {
    const common = useCommon()
    const {
      updateProfile,
    } = accountService()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      updateProfile,
      refFormObserver,
      getValidationState,
      refInputEl,
      previewEl,
      inputImageRenderer,
      common,
    }
  },
}
</script>
