<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("PageAccount.tabGeneral") }}</span>
      </template>
      <account-setting-general
        v-if="profileData"
        :general-data="profileData"
      />
    </b-tab>
    <!--/ general tab -->
    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("PageAccount.tabChangePassword") }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
    <!-- notification -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("PageAccount.tabNotifications") }}</span>
      </template>

      <account-setting-notification
        v-if="profileData"
        :general-data="profileData"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import accountService from '@/services/account.service'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingNotification,
  },
  setup() {
    const {
      fetchProfile,
    } = accountService()

    const fetchProfileData = ref(null)

    fetchProfile(data => {
      fetchProfileData.value = data
    })

    return {
      profileData: fetchProfileData,
    }
  },
}
</script>
