<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(changePassword)"
      >
        <b-row>
          <!-- current password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('PageAccount.changePassword.currentPassword')"
              rules="required|password|min:8"
            >
              <b-form-group
                :label="$t('PageAccount.changePassword.currentPassword')"
                label-for="account-current-password"
              >
                <b-input-group>
                  <b-form-input
                    id="account-current-password"
                    v-model="currentPasswordValue"
                    name="current-password"
                    :placeholder="$t('PageAccount.changePassword.currentPassword')"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeOld"
                    autofocus
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <b-form-text
                  v-if="wrongPasswords.includes(currentPasswordValue)"
                >
                  {{ $t('PageAccount.changePassword.incorrectPassword') }}
                </b-form-text>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('PageAccount.changePassword.newPassword')"
              rules="required|password|min:8"
            >
              <b-form-group
                :label="$t('PageAccount.changePassword.newPassword')"
                label-for="account-new-password"
              >
                <b-input-group>
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    name="new-password"
                    :placeholder="$t('PageAccount.changePassword.newPassword')"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeNew"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->
          <!-- confirm password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('PageAccount.changePassword.confirmNewPassword')"
              :rules="`required|confirmed:${$t('PageAccount.changePassword.newPassword')}`"
              vid="new-password"
            >
              <b-form-group
                :label="$t('PageAccount.changePassword.confirmNewPassword')"
                label-for="account-confirm-new-password"
              >
                <b-input-group>
                  <b-form-input
                    id="account-confirm-new-password"
                    v-model="confirmPassword"
                    name="confirm-password"
                    :placeholder="$t('PageAccount.changePassword.newPassword')"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeConfirm"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ confirm password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-if="!wrongPasswords.includes(currentPasswordValue)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              enabled
            >
              {{ $t("PageAccount.changePassword.btnSaveChanges") }}
            </b-button>
            <b-button
              v-if="wrongPasswords.includes(currentPasswordValue)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              disabled
            >
              {{ $t("PageAccount.changePassword.btnSaveChanges") }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback, BFormText,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import useCommon from '@/libs/common'
import Ripple from 'vue-ripple-directive'
import accountService from '@/services/account.service'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BFormText,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPasswordValue: '',
      newPasswordValue: '',
      confirmPassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeConfirm: 'password',
      wrongPasswords: [],
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
    resetuserData() {
      this.currentPasswordValue = ''
      this.newPasswordValue = ''
      this.confirmPassword = ''
    },
    changePassword() {
      const params = {
        oldPassword: this.currentPasswordValue,
        newPassword: this.newPasswordValue,
        confirmPassword: this.confirmPassword,
      }
      this.updateProfilePassword(params, data => {
        if (data.status === 400) {
          this.common.toastError(data.msg)
          if (!this.wrongPasswords.includes(this.currentPasswordValue)) {
            this.wrongPasswords.push(this.currentPasswordValue)
          }
        } else {
          router.go()
          this.common.toastSuccess('Contraseña modificada')
          this.wrongPasswords = []
          // this.resetuserData()
        }
      })
    },
  },
  setup() {
    const common = useCommon()
    const {
      updateProfilePassword,
    } = accountService()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      updateProfilePassword,
      refFormObserver,
      getValidationState,
      common,
    }
  },
}
</script>
